<template>
  <div class="activity-details g-content">
    <div class="g-content-l">
      <div class="l-top">
        <div class="l-banner">
          <!-- 未到开播时间 -->
          <div style="height: 100%" @mouseenter="showbutton = true" @mouseleave="showbutton = false">
            <swiper class="my-swiper gallery" :options="swiperOptionTop" ref="swiperTop">
              <swiper-slide v-if="videos && videos.url">
                <videoPlayer :url="videos.url" :cover="videos.cover" @VideoPaly="VideoPaly"></videoPlayer>
              </swiper-slide>
              <swiper-slide v-for="(item, index) in banner" :key="'gSwiperCommon-' + index">
                <img :src="item" class="swiper-cover" />
              </swiper-slide>
            </swiper>
            <div v-show="swiperList.length > 1 && showbutton">
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </div>
          </div>

          <p class="l-banner-bot">
            <span>
              <img src="@/assets/img/icon-zs.png" />
              {{ actData.likeCount }}
            </span>
          </p>
        </div>
        <div class="l-banner-bot">
          <p class="l-title-r-p1" :class="isFollow ? 'l-title-r-yes' : ''">
            <span class="heart" @click="follow()" v-if="!isFollow">
              <i class="icon iconfont icon-love"></i>
              Follow
            </span>
            <span class="heart" @click="follow()" v-else> {{ $t("lang.Followed") }} </span>
            <span class="num">{{ actData.followCount }}</span>
          </p>
          <span class="likeCount" @click="zan()">
            <!-- <img src="@/assets/img/icon-zs.png" > -->
            <i class="icon iconfont icon-like" :class="isLike ? 'icon-like-active' : ''"></i>
            {{ actData.likeCount }}
          </span>
          <liveDianzan></liveDianzan>
        </div>
      </div>
      <div class="l-title">
        <div class="l-title-l">
          <div class="l-title-l-img">
            <img :src="banner[0]" />
          </div>
          <div class="l-title-l-info">
            <div class="h3">
              <h3>{{ actData.title }}</h3>
              <div class="ml5-brand" v-if="actData.liveStateIndex === 1">
                <span class="u-brand2">Living</span>
              </div>
            </div>
            <p>Date：{{ actData.startTime | moment("YYYY/MM/DD HH:mm") }}~{{ actData.endTime | moment("YYYY/MM/DD HH:mm") }}</p>
            <p>Add.：{{ actData.address }}</p>
          </div>
        </div>
        <div class="l-title-r">
          <p class="l-title-r-p2" v-if="actData.enabledBuyingTicket" @click="LinkToActReg(actId)">
            Sign up
          </p>
        </div>
      </div>

      <div class="l-nav">
        <div class="type-tab">
          <span v-for="(item, index) in typeList" :key="index" @click="
              activeIndex = index;
              selectTab(item);
            " :class="activeIndex == index ? 'active' : ''">
            {{ item }}
          </span>
        </div>
      </div>

      <div class="m-card3" id="Act" v-show="introduction&&introduction.length">
        <div class="mc-top">
          <div class="mc-top-title">
            <span class="mc-top-text mc-top-txt s-bold">{{ $t("lang.activity_Info") }}</span>
          </div>
        </div>
        <div class="mc-content detail">
          <div class="comm_text" v-if="introduction&&introduction.length">
            <div v-for="(item, index) in introduction" :key="index">
              <!-- 文本 -->
              <p class="int-text" v-if="item.text" :style="{
                  'font-size': item.text.fontSize,
                  color: item.text.color,
                  'text-align': item.text.position,
                }" v-html="$service.replaceSpeace(item.text.content)"></p>

              <!-- 图片 -->
              <div class="int-img" v-if="item.picture && item.picture.type == 1" :style="{
                  'padding-left': item.picture.padding + 'px',
                  'padding-right': item.picture.padding + 'px',
                }">
                <div v-for="(itm, idx) in item.picture.list" :key="idx" :style="{ 'margin-bottom': item.picture.spacing + 'px' }">
                  <img :src="itm.url" style="width: 100%; display: block" />
                </div>
              </div>

              <div class="int-img" style="width: 750px;" v-if="item.picture && item.picture.type == 2 && item.picture.list && item.picture.list.length">
                <Carousel loop autoplay :radius-dot="true" :autoplay-speed="5000">
                  <CarouselItem v-for="(item, index) in item.picture.list" :key="'pic' + index">
                    <img :src="item.url" style="width: 100%;height: 100%;" />
                  </CarouselItem>
                </Carousel>
              </div>

              <!-- 视频 -->
              <div class="int-video" style="position: relative;margin-top: 20px;" v-if="item.video">
                <videoPlayer :url="item.video.link" :cover="item.video.cover" :time="item.video.Time"></videoPlayer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-card3" id="Date" v-if="actData&&actData.showSchedule" v-show="dateFinish">
        <div class="mc-top">
          <div class="mc-top-title">
            <span class="mc-top-text mc-top-txt s-bold">{{ $t("lang.date_Info") }}</span>
          </div>
        </div>
        <div class="mc-content">
          <actDate :actData="actData" v-if="actData" @finishInit="finishDate"></actDate>
        </div>
      </div>
      <div class="m-card3" id="Img" v-show="ImgFinish">
        <div class="mc-top">
          <div class="mc-top-title">
            <span class="mc-top-text mc-top-txt s-bold">Pictures</span>
          </div>
        </div>
        <div class="mc-content">
          <imgLive @finishImg="finishImg"></imgLive>
        </div>
      </div>
    </div>
    <div class="g-content-r">
      <userInfo @watchliveSend="send" ref="barrage"></userInfo>
      <compInfo :actData="actData" v-if="actData"></compInfo>
    </div>

    <Modal v-model="showReg" :mask-closable="false" cancel-text="cancel" ok-text="confirm" class-name="vertical-center-modal" @on-ok="$router.push(`/login/${exhibitionId}`)" @on-cancel="showReg=false;$router.push(`/home/activity/${exhibitionId}`)">
      <p style="padding:20px">Please sign in to watch.</p>
    </Modal>
 
    <Modal v-model="showReg2" :mask-closable="false" cancel-text="cancel" ok-text="confirm" class-name="vertical-center-modal" @on-ok="linkToRegSteps()" @on-cancel="showReg2=false;$router.push(`/home/activity/${exhibitionId}`)">
      <p style="padding:20px">Please complete the information to watch.</p>
    </Modal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import userInfo from "./components/user-info-nolive";
import compInfo from "./components/compInfo";
import actDate from "./components/date";
import imgLive from "./components/imgLive";
import videoPlayer from "@components/videoPlayer/videoPlayer.vue";
import liveVideo from "./components/live-video";
import liveIng from "./components/live-ing";
import liveAway from "./components/live-away";
import * as signalR from "@microsoft/signalr";
import config from "@assets/config.json"; // api 请求json
import liveDianzan from "./components/live-dianzan-canvas";

export default {
  name: "activity-details",
  data () {
    return {
      showReg: false,
      showReg2: false,

      typeList: ['Introduction', 'Schedule', 'Live pictures'],
      swiperOptionTop: {
        autoplay: true, //等同于以下设置
      },
      activeIndex: 0,
      actData: "",
      isLike: false, // 点赞
      isFollow: false, // 关注
      regMsg: {}, // 开启报名的信息
      banner: [],
      timeoutflag: null,
      handCount: 0, // 鼓掌的次数
      isShowSubTitle: false,
      // 直播的数据
      liveStatus: 1, // 主播的直播状态  0：未开始 1：直播中 2：直播结束 3：直播断开
      isBlack: false, //是否被拉黑
      showBg: true,
      isSafari: false,
      hub: null,
      liveUrl: "",
      isViewLive: null, // 是否可以访问
      isShowPay: false,
      langType: "zh", //字慕语言type
      showSubtitle: "",
      docTitle: "直播",
      viewerList: [],
      viewerCount: 0,
      queryLiveViewer: "",
      isLiveTime: false, // 是否为直播的当天日期
      videos: null,
      autoPlayVal: 3000,
      isAutoplay: 3000, //是否自动播放
      shareV: {
        content: "",
        poster: "",
      },
      roomid: "",
      dateFinish: true,
      ImgFinish: true,
      swiperList: [],
      showbutton: false,
      introduction: [],
    };
  },
  components: {
    userInfo,
    compInfo,
    actDate,
    imgLive,
    videoPlayer,
    liveVideo,
    liveAway,
    liveIng,
    liveDianzan,
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      userInfo: "getUser",
      viewer: "getViewer",
      viewerId: "getViewerId",
    }),
    actId () {
      return this.$route.query.id;
    },
    option () {
      return {
        cover: this.actData.liveBackgroundUrl || "https://img.zhanshangxiu.com/showonline/file/202010/20201030/209a518f609b466db8ae37dbae41d0ed.jpg",
      };
    },
    swiperTop () {
      return this.$refs.swiperTop.swiper;
    },
    isLogin () {
      return this.userInfo && this.userInfo.id;
    },
    hasUserInfo () {
      return this.userInfo && this.userInfo.userId ? true : false;
    },
    isRegOk () {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole && this.userInfo.inMember.userRole != "VISITOR" && this.userInfo.inMember.userRole != "Visitor";
    },
  },
  created () {
    this.init();
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXSEn",
      addActZan: "act/addActZan",
      delActZan: "act/delActZan",
      addActLove: "act/addActLove",
      delActLove: "act/delActLove",
      likeTo: "act/likeTo",
      LinkActReg: "linkTo/LinkActReg",
      unverifiedDialog: "unverifiedDialog",
      getShareConfig: "share/getShareConfig",
      addAllView: "addAllView",
      addViewLikeIp: "addViewLikeIp",
      linkToRegSteps: "linkTo/linkToRegSteps",
    }),
    addView () {
      let opt = {
        exhibitionId: this.exhibitionId,
        target: "activity",
        targetId: this.actId,
      };
      if (this.userInfo && this.userInfo.userId) {
        opt.userId = this.userInfo.userId;
      }
      this.addAllView(opt);
    },
    async setShare () {
      let data = await this.getShareConfig({
        id: this.exhibitionId,
        name: "share.activity.config",
      });
      let share = {
        url: location.href,
        title: this.actData.title || "活动详情",
        desc: "",
        summary: "",
        pics: this.banner && this.banner.length ? this.banner[0] : "",
      };
      if (data) {
        let dataJson = JSON.parse(data);
        if (dataJson.content) {
          dataJson.content = dataJson.content.replace(/【活动】/g, this.actData.title);
          dataJson.content = dataJson.content.replace(/【公司】/g, this.actData.company ? this.actData.company.name : "");
          dataJson.content = dataJson.content.replace(/【展位号】/g, this.actData.exhibitor ? this.actData.exhibitor.boothInfo : "");
          share.title = dataJson.content;
        }
        if (dataJson.des) {
          dataJson.des = dataJson.des.replace(/【活动】/g, this.actData.title);
          dataJson.des = dataJson.des.replace(/【公司】/g, this.actData.company ? this.actData.company.name : "");
          dataJson.des = dataJson.des.replace(/【展位号】/g, this.actData.exhibitor ? this.actData.exhibitor.boothInfo : "");
          share.desc = dataJson.des;
          share.summary = dataJson.des;
        }
      }
      this.$store.commit("setShare", share);
    },
    VideoPaly () {
      console.log("视频正在播放");
      this.swiperTop.autoplay.stop();
      console.log(this.$refs.swiperTop.swiper);
    },
    init () {
      this.addView();
      this.getActDetails();
      this.getHub();
    },
    finishDate (bool) {
      this.dateFinish = bool;
    },
    finishImg (bool) {
      this.ImgFinish = bool;
    },
    // 直播------------------------
    //用户发送弹幕
    send (text) {
      //  已经进入，得到直播间观众信息
      if (!this.hub) {
        this.$Message.error("连接失败");
        this.reContact();
        return;
      }
      this.text = text;
      // console.log(this.hub,this.viewer,'发送时');
      if (this.viewer && this.viewerId) {
        var comment = {};
        comment.roomId = this.roomid;
        comment.userId = this.viewer.UserId;
        comment.nameEn = this.userInfo.nameEn || '';
        comment.userInfo = this.viewer.UserInfo;
        comment.viewerId = this.viewerId;
        comment.content = this.text;
        this.hub.invoke("ViewerComment", this.roomid, comment);
      } else {
        //未进入，先进入直播
        console.log("未发送，进入直播");
        this.viewerInit();
      }
    },
    //事件监听
    getLoadMethod () {
      //观众进入响应通知
      this.hub.on("ViewerEntryResponseNotice", (msg) => {
        console.log("观众进入响应通知", msg, "msg");
        if (msg.Data.viewer) {
          this.$store.commit("setViewer", msg.Data.viewer);
          this.$store.commit("setViewerId", msg.Data.viewer.Id);
          if (msg.Data.viewer.IsBlack) {
            this.$Message.error("您已被拉黑");
            this.isBlack = true;
          }
          if (this.text) {
            this.send(this.text);
          }
        }
        // 正在直播
        if (msg && msg.Code === 1) {
          that.showBg = false;
          that.liveUrl = msg.Data.pulls[2]; // 直播地址
          that.showBg = true;
        }
        that.liveStatus = msg.Code;
        // that.liveStatus = 3;
        // 直播状态码（LiveCode）：0：未播、1：直播中、2：暂停中、3：已结束、4：已被下架、5：取消、6：未审核通过、7：Under review、8：审核通过
      });
      // 观众进入通知
      this.hub.on("ViewerEntryNotice", (res, onlineCount) => {
        let userinfo = res.UserInfo ? JSON.parse(res.UserInfo) : "";
        let model = {
          id: userinfo.id,
          isEntry: true,
          name: userinfo.nickName || userinfo.name,
          content: "",
        };
        // this.$refs["barrage"].viewerEntry(model);
        console.log(res, "观众进入通知");
      });
      // 观众离开通知
      this.hub.on("ViewerLeaveNotice", (msg) => {
        console.log(msg, "观众离开通知");
      });
      // 留言删除通知（留言删除）
      this.hub.on("DeleteLiveCommentNotice", (commentid) => {
        this.$refs["barrage"].delBarrage(commentid);
      });
      // 观众留言通知（留言接收）
      this.hub.on("ViewerCommentNotice", (comment) => {
        let viewerName = "visitor";
        if (comment.UserInfo) {
          viewerName = JSON.parse(comment.UserInfo).nickName || JSON.parse(comment.UserInfo).name;
        }
        let model = {
          id: comment.Id,
          isEntry: false,
          name: viewerName,
          text: comment.Content,
        };
        console.log(model, "观众留言通知");
        this.$refs["barrage"].dataPush(model);
      });
      // 观众留言响应通知
      this.hub.on("ViewerCommentResponseNotice", (res) => {
        console.log("发送留言， 响应", res);
        //已授权
        if (!res.Result) {
          switch (this.liveStatus) {
            case 0:
              this.$Message.error("直播尚未开始");
              break;
            case 1:
              this.$Message.error(res.Message);
              break;
            case 2:
              this.$Message.error("直播已经结束");
              break;
            case 3:
              this.$Message.error("主播暂时不在");
              break;
            default:
              this.$Message.error(res.Message);
              break;
          }
        }
      });
    },
    //建立连接
    getHub () {
      this.roomid = this.actId;
      let roomId = "roomId=" + this.roomid;
      this.hub = new signalR.HubConnectionBuilder()
        .withAutomaticReconnect()
        .withUrl(`${config.api.liveApi}/hub/live?${roomId}`)
        .build();
      // 连接回调
      this.hub
        .start()
        .then((e) => {
          console.log(this.hub, "连接成功");
          this.viewerInit();
          this.getLoadMethod();
          // this.reContact(); // 超时重新连接
        })
        .then((errorMsg) => {
          console.log(errorMsg, "err");
        });
    },
    // 重新连接
    reContact () {
      const that = this;
      (function (err) {
        setTimeout(() => {
          that.getHub();
        }, 200);
      });
    },
    // 观众进入
    viewerInit () {
      let viewerInfo = {};
      viewerInfo.roomId = this.roomid;
      viewerInfo.userId = this.userInfo.userId;
      viewerInfo.memberId = this.userInfo.id;
      let model = {
        name: this.userInfo.name,
        nickName: this.userInfo.nickName,
        userId: this.userInfo.userId,
        avatarUrl: this.userInfo.avatarUrl,
      };
      viewerInfo.userInfo = JSON.stringify(model);
      this.hub.invoke("ViewerEntry", this.roomid, viewerInfo);
    },
    statechange (e) {
      console.log(e);
    },
    //观众离开
    close () {
      if (this.userId && this.userInfo) {
        console.log("离开");
        this.hub.invoke("ViewerLeave", this.roomid, this.userId);
      }
    },
    ready () {
      console.log("ready");
    },
    play () {
      console.log("play");
      this.showBg = false;
    },
    ended () {
      console.log("ended");
      this.showBg = true;
    },
    liveStreamStop () {
      console.log("liveStreamStop");
      this.showBg = true;
    },
    error () {
      console.log("error");
      this.showBg = true;
    },
    // 直播------------------------

    LinkToActReg (id) {
      if (!this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      this.LinkActReg(id);
    },

    async getActDetails () {
      let query = `#graphql
          query($id: String!) {
            activityQuery{
              get(id: $id){
                anonymousView
                acvitityType
                address
                banners
                introduction
                company{
                  nameEn
                  id
                  logo
                  category
                  address
                  region
                  regionEn
                }
                endTime
                exhibitor{
                  id
                  boothHall
                  boothInfo
                  activityCount
                  productCount
                  category
                  categoryEn
                  continuouNumber
                  productNumber
                  activityNumber
                }
                exhibitionId
                exhibitorId
                externalLink
                enabledOtherPull
                otherPullUrls
                id
                startTime
                stateIndex
                title
                userCount
                likeCount
                videos
                viewCount
                followCount
                isFollow(userId:"${this.userInfo.userId}")
                isLike(userId:"${this.userInfo.userId}")
                hasReg(userId:"${this.userInfo.userId}")
                regStartAt
                regEndAt
                regFee
                regMaximum
                liveStartTime
                liveEndTime
                liveStateIndex
                liveViewType
                liveBanners
                liveViewPassword
                livePaymentAmount
                liveBackgroundUrl
                liveEnabledSubtitles 
                liveEnabledTranslate 
                liveTranslateLangs   
                showLiveImage
                showPopularize
                showSchedule
                showTheme
                enabledGift
                enabledBuyingTicket
                isExhibitionActivity  
              }
            }
          }
      `;
      let opt = {
        query: query,
        variables: {
          id: this.actId,
        },
      };
      let data = await this.graphqlPost(opt);
      if (data.data.activityQuery.get) {
        this.actData = data.data.activityQuery.get;
        console.log('showRegConfirm: hasUserInfo', this.hasUserInfo);
        console.log('showRegConfirm: anonymousView', this.actData.anonymousView);
        console.log('showRegConfirm: isRegOk', this.isRegOk);
        if (this.actData && !this.actData.anonymousView) { // 有活动信息且不允许匿名观看
          if (!this.hasUserInfo) {// 没有用户信息
            this.showRegConfirm(); // 用户未登录或已登录但没手机号 且活动没有开启匿名观看 弹窗提示“请注册后观看“
          } else {
            if (!this.isRegOk) { // 有用户信息 且没有在当前展会下注册 则跳转j
              this.showRegConfirm2(); // 用户未登录或已登录但没手机号 且活动没有开启匿名观看 弹窗提示“请注册后观看“
            }
          }
        }
        this.isLike = this.actData.isLike;
        this.isFollow = this.actData.isFollow;
        if (this.actData.banners.indexOf("[") != -1) {
          this.banner = JSON.parse(this.actData.banners);
        } else {
          this.banner = [this.actData.banners];
        }
        if (this.actData.videos) {
          this.videos = JSON.parse(this.actData.videos);
        }
        if (this.actData.introduction && this.actData.introduction.indexOf('[') !== -1) {
          this.introduction = JSON.parse(this.actData.introduction)
        } else if (this.actData.introduction) {
          let arr = [{ "text": { "type": 1, "content": this.actData.introduction, "fontSize": "14px", "color": "#000", "position": "left" } }];
          this.introduction = arr;
        }
        this.regMsg = {
          regStartAt: this.actData.regStartAt,
          regEndAt: this.actData.regEndAt,
          regFee: this.actData.regFee,
          regMaximum: this.actData.regMaximum,
        };
        if (this.actData.showSchedule) {
          this.typeList = ['Introduction', 'Schedule', 'Live pictures'];
        } else {
          this.typeList = ['Introduction', 'Live pictures'];
        }
        // let liveStartTime = this.actData.liveStartTime.slice(0,11).replace(/-/g, "/"); // 开播的时间；
        // const Today = formatTime(new Date()).slice(0,11);
        // if(liveStartTime == Today) { // 开播时间是否为今天
        //   this.isLiveTime = true;
        // }else{
        //   this.isLiveTime = false;
        // }
        // console.log(this.isLiveTime,'开播时间是否为今天');
        if (this.actData.liveStateIndex == 1) {
          this.isLiveTime = true;
        } else {
          this.isLiveTime = false;
        }

        this.docTitle = this.actData.title;
        console.log(this.actData, "活动详情");
        if (this.videos && this.videos.url) {
          this.swiperList.push(this.videos);
        }
        this.swiperList = [...this.swiperList, ...this.banner];
        console.log(this.swiperList, "this.swiperList");
        // this.getShareVal();
        // this.getLiveUrl(this.roomid); // 获取房间的拉流地址
        // this.checkLiveView();
        // this.$store.commit("setExhibitionId",this.actData.exhibitionId);
      }
      this.setShare();
    },
    follow () {
      // 关注
      if (!this.isLogin) {
        // this.unverifiedDialog(this);
        this.follow2();
        return;
      }
      if (this.isFollow) {
        // 已关注取消关注
        let body = {
          activityId: this.actId,
          userId: this.userInfo.userId,
        };
        this.delActLove(body);
        this.isFollow = !this.isFollow;
        this.actData.followCount -= 1;
        this.$Message.success("Cancel");
      } else {
        // 未关注添加关注
        let body = {
          exhibitionId: this.exhibitionId,
          activityId: this.actId,
          userId: this.userInfo.userId,
          nickName: this.userInfo.nickName || this.userInfo.name,
          avatarUrl: this.userInfo.avatarUrl,
        };
        this.addActLove(body);
        this.isFollow = !this.isFollow;
        this.actData.followCount += 1;
        this.$Message.success(this.$t("lang.Followed"));
      }
    },
    async follow2 () {
      let opt = {
        targetType: "activity",
        targetId: this.exhibitorId,
        actionType: "view",
      };
      let result = await this.addViewLikeIp(opt);
      if (result.result) {
        // this.$Message.success("Success");
      } else {
        this.$Message.error(result.message);
      }
    },

    async zan () {
      if (!this.isLogin) {
        this.unverifiedDialog(this);
        return;
      }
      if (this.isLike) {
        // 已点赞取消点赞
        this.$Message.info("You've already liked it");
      } else {
        // 未点赞添加点赞
        let body = {
          exhibitionId: this.exhibitionId,
          activityId: this.actId,
          userId: this.userInfo.userId,
          nickName: this.userInfo.nickName || this.userInfo.name,
          avatarUrl: this.userInfo.avatarUrl,
          number: 1,
        };
        await this.addActZan(body);
        this.isLike = !this.isLike;
        this.actData.likeCount += 1;
        this.$Message.success("Success");
      }
    },
    // async zan () {
    //   let opt = {
    //     targetType: "activity",
    //     targetId: this.actId,
    //     actionType: "like",
    //   };
    //   let result = await this.addViewLikeIp(opt);
    //   if (result.result) {
    //     // this.$Message.success("Success");
    //   } else {
    //     this.$Message.error(result.message);
    //   }
    // },

    selectTab (item) {
      switch (item) {
        case "Introduction":
          document.getElementById("Act").scrollIntoView();
          break;
        case "Schedule":
          if (this.dateFinish) {
            document.getElementById("Date").scrollIntoView();
          } else {
            // this.$Message.info("暂无日程");
            this.$Message.info("No agenda");
          }
          break;
        case "Live pictures":
          if (this.ImgFinish) {
            document.getElementById("Img").scrollIntoView();
          } else {
            // this.$Message.info("暂无图片直播");
            this.$Message.info("No live pictures");
          }
      }
    },

    //掌声点击
    async zsGoto () {
      let opt = {
        roomId: this.roomid,
        number: 1,
        showAnimation: true,
      };
      await this.likeTo(opt);
      if (window.navigator.vibrate) {
        // console.log('支持')
        window.navigator.vibrate(200);
      }
    },

    showRegConfirm () {
      this.showReg = true;
      // this.$Modal.confirm({
      //   content: 'Please sign in to watch.',
      //   cancelText: 'cancel',
      //   okText: 'confirm',
      //   onOk: () => {
      //     this.$router.push(`/login/${this.exhibitionId}`);
      //   },
      //   onCancel: () => {
      //     console.log('取消');
      //     this.$router.push(`/home/activity/${this.exhibitionId}`);
      //   }
      // });
    },
    showRegConfirm2 () {
      this.showReg2 = true;
      // this.$Modal.confirm({
      //   content: 'Please complete the information to watch.',
      //   cancelText: 'cancel',
      //   okText: 'confirm',
      //   onOk: () => {
      //     this.linkToRegSteps();
      //   },
      //   onCancel: () => {
      //     console.log('取消');
      //     this.$router.push(`/home/activity/${this.exhibitionId}`);
      //   }
      // });
    },
  },
  beforeDestroy () {
    if (this.hub) {
      console.log("onUnload", this.hub);
      //手动关闭连接
      if (this.isLogin) {
        this.hub.invoke("ViewerLeave", this.roomid, this.userInfo.userId);
      }
      // this.hub.invoke("ViewerLeave", this.roomid, this.userInfo.userId);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.swiper-pagination {
  font-size: 0;
  display: flex;
  justify-content: flex-end;
  padding-right: 18px;
  bottom: 22px;
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    border-radius: 0;
    background-color: #ddd;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: #ed6e02;
    opacity: 1;
  }
}
.swiper-button-next {
  background-image: url("../img/arrow-r.png");
  background-size: contain;
  width: 44px;
}
.swiper-button-prev:focus {
  outline: none;
}
.swiper-button-next:focus {
  outline: none;
}
.swiper-button-prev {
  width: 44px;
  background-image: url("../img/arrow-l.png");
  background-size: contain;
}
</style>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.icon-like {
  font-size: 14px;
  color: #bfbfbf;
  margin-right: 4px;
}
.icon-like-active {
  color: #ff5c00;
}
.type-tab {
  width: 100%;
  height: 48px;
  background-color: #ededed;
  border: solid 1px #e5e5e5;
  margin: 20px 0 10px;
  span {
    display: inline-block;
    text-align: center;
    line-height: 48px;
    padding: 0 30px;
    cursor: pointer;
  }
  .active {
    background: #fff;
    @include font_color(#1890ff);
    border-top: 4px solid #1890ff;
    @include border_color(#1890ff);
    line-height: 42px;
  }
}
.icon-love-yes {
  color: #ff5c00;
}
.activity-details {
  margin-top: 20px;
  margin-bottom: 45px;
}
.my-swiper.gallery {
  height: 100%;
  width: 100%;
}
.g-content {
  display: flex;
  &-l {
    width: 880px;
    .l-top {
      border: solid 1px #ededed;
    }
    .l-banner {
      width: 100%;
      height: 496px;
      position: relative;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
      &-bot {
        width: 100%;
        height: 60px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        position: relative;
        .likeCount {
          display: inline-block;
          width: 68px;
          height: 30px;
          border-radius: 15px;
          background: #f7f7f7;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #333;
          cursor: pointer;
          img {
            display: block;
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
        }
      }
    }
    .l-title {
      width: 100%;
      height: 120px;
      background: #fff;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      margin-top: 20px;
      border: solid 1px #ededed;
      &-l {
        width: 70%;
        display: flex;
        align-items: center;
        &-img {
          width: 140px;
          height: 80px;
          border: solid 1px #e6e6e6;
          margin-right: 14px;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        &-info {
          .h3 {
            display: flex;
            h3 {
              max-width: 360px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: normal;
              font-size: 16px;
              line-height: 1.6;
            }
            .ml5-brand {
              margin-left: 14px;
            }
          }
          p {
            color: #666;
            line-height: 1.6;
          }
        }
      }
      &-r {
        p {
          width: 148px;
          height: 30px;
          background-color: #fff;
        }
        &-p1 {
          cursor: pointer;
          span {
            display: inline-block;
            text-align: center;
            height: 30px;
            width: 74px;
            line-height: 30px;
          }
          .heart {
            @include background_color(#1890ff);
            color: #fff;
            border-radius: 15px 0 0 15px;
          }
          .num {
            color: #666;
            border: 1px solid #1890ff;
            @include border_color(#1890ff);
            border-radius: 0 15px 15px 0;
          }
        }
        &-yes {
          .heart {
            background-color: #e5e5e5;
            color: #fff;
            top: -1px;
            position: relative;
          }
          .num {
            border-color: #e5e5e5;
          }
        }
        &-p2 {
          border-radius: 15px;
          border: 1px solid #1890ff;
          @include border_color(#1890ff);
          cursor: pointer;
          margin-top: 14px;
          text-align: center;
          @include font_color(#1890ff);
          line-height: 30px;
        }
      }
    }
  }
  &-r {
    width: 310px;
    margin-left: 10px;
  }
}
.mc-content {
  padding: 20px;
  &.detail {
    padding: 0 20px;
  }
  .mc-intor {
    line-height: 1.5;
    text-align: inherit;
  }
}
.comm_text {
  background: #fff;
  color: #333;
  > div {
    &:last-child {
      .int-video,
      .int-img {
        margin-bottom: 20px;
      }
    }
  }
  > span {
    line-height: 1;
    padding: 0 0 20px 30;
    font-size: 32px;
    display: inline-block;
  }
  p {
    line-height: 1.6;
    font-size: 14px;
  }
  img {
    display: block;
  }
  .int-text {
    padding: 30px 0;
  }
}
</style>
<style lang="scss">
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}
</style>